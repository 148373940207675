import JSZip from 'jszip';
import _ from 'lodash';
const downloadURL = (url, fileName) => {
  const el = document.createElement('a');
  el.setAttribute('href', url);
  el.setAttribute('download', fileName);
  document.body.appendChild(el);
  el.click();
  el.remove();
};
const createZip = files => {
  const zip = new JSZip();
  files.forEach(([fileName, blobPromise]) => {
    zip.file(fileName, blobPromise);
  });
  return zip.generateAsync({
    type: 'uint8array'
  });
};
const generateZip = async (documents, projectName) => {
  const fileNames = documents.map(document => `${projectName}/${document.internal_path}/${document.filename}`);
  const promises = documents.map(document => {
    return new Promise((resolve, reject) => {
      fetch(document.url.toString()).then(response => response.blob()).then(resolve).catch(reject);
    });
  });
  return Promise.all(promises).then(blobPromises => {
    const files = _.zip(fileNames, blobPromises);
    return createZip(files);
  }).then(content => {
    const blob = new Blob([content], {
      type: 'application/zip'
    });
    const url = URL.createObjectURL(blob);
    downloadURL(url, `${projectName || 'GTI-document-download'}.zip`);
    URL.revokeObjectURL(url);
  });
};
export { downloadURL, createZip, generateZip };