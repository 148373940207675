import { DateTime } from 'luxon';

/**
 * Returns the number of days between two dates.
 *
 * @param {Date} start
 * @param {Date} end
 * @returns {number}
 */
export const getDaysBetween = (start, end) => {
  const start_date = DateTime.fromJSDate(start);
  const end_date = DateTime.fromJSDate(end);
  return end_date.diff(start_date, 'days').days;
};

/**
 * Return the number of days since a given date.
 *
 * @param {string} date
 * @returns {number}
 */
export const getDaysSince = date => {
  const days = DateTime.fromISO(date).diffNow('days').days;
  return Math.floor(Math.abs(days));
};

/**
 * Formats a given date string into a localized date string in the 'en-GB' locale.
 *
 * @param {string} date - The date string to be formatted.
 * @return {string} The formatted date string in the format 'dd/mm/yy'.
 */
export const formatDate = date => {
  return DateTime.fromISO(date).setLocale('en-gb').toLocaleString({
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });
};

/**
 * Formats a given date string into a localized date string in the 'en-GB' locale.
 *
 * @param {string} date - The date string to be formatted.
 * @return {string} The formatted date string in the format 'dd/mm/yyyy, hh:mm'.
 *
 */
export const formatDateTime = date => {
  return DateTime.fromISO(date).setLocale('en-gb').toLocaleString({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};