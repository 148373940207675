// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildQueryString = params => {
  if (isEmpty(params)) return '';
  params = removeEmptyValues(params);
  return '?' + Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isEmpty = obj => Object.values(obj).every(value => !value);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeEmptyValues = obj => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));