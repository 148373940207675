import React from 'react';
import { Provider } from 'mobx-react';
import App from 'next/app';
import NextNprogress from 'nextjs-progressbar';
import Cookies from 'js-cookie';

import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';
import { ToasterContainer, PLACEMENT } from 'baseui/toast';

import { styletron } from '../styletron';

import { COOKIE_NAMES } from '@gtintel/platform-constants';
import { ChatProvider, FeaturesProvider } from '@gtintel/platform-context';
import { RootStore } from '@gtintel/platform-stores';

import HeapAnalytics from '../components/HeapAnalytics';
import GlobalStyles from '../styles/global';
import './app.css';

import LogRocket from 'logrocket';

const trackerDomains = ['.heapanalytics.com', 'lr-in-prod.com', 'sentry.io'];
const authUrls = ['api/token', 'api/2fa/token-verify'];

if (!['dev', 'local'].includes(process.env.EXECUTION_ENV)) {
  LogRocket.init(process.env.LOGROCKET_APP_KEY, {
    release: String(process.env.SENTRY_RELEASE),
    dom: {
      textSanitizer: process.env.EXECUTION_ENV === 'prod',
      inputSanitizer: process.env.EXECUTION_ENV === 'prod',
    },
    network: {
      requestSanitizer: request => {
        const urlLowerCase = request.url.toLowerCase();

        if (authUrls.some(url => urlLowerCase.includes(url))) {
          request.body = null;
        }

        if (trackerDomains.some(item => urlLowerCase.includes(item))) {
          return null;
        }

        return request;
      },
    },
  });
}

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    const mobxStore = new RootStore();
    ctx.mobxStore = mobxStore;

    return {
      pageProps: Component.getInitialProps ? await Component.getInitialProps(ctx) : {},
    };
  }

  constructor(props) {
    super(props);
    this.mobxStore = new RootStore();
  }

  componentDidMount() {
    const user = Cookies.getJSON(COOKIE_NAMES.INFOR_USER);
    if (user?.id) {
      LogRocket.identify(String(user.id), { name: user.full_name, email: user.email });
    }
  }

  render() {
    const { Component, pageProps } = this.props;
    const TIMEOUT = 300;

    return (
      <>
        <NextNprogress
          color="#ffba00"
          startPosition={0.3}
          stopDelayMs={200}
          height={3}
          showOnShallow={true}
          options={{ showSpinner: false }}
        />
        <FeaturesProvider>
          <ChatProvider>
            <Provider {...this.mobxStore}>
              {/* <Provider session={pageProps.session}> */}
              <StyletronProvider value={styletron}>
                <BaseProvider theme={LightTheme}>
                  <Component {...pageProps} />
                  <ToasterContainer autoHideDuration={3000} placement={PLACEMENT.bottomRight}></ToasterContainer>
                </BaseProvider>
              </StyletronProvider>
              <HeapAnalytics />
              {/* </Provider> */}
            </Provider>
          </ChatProvider>
        </FeaturesProvider>

        <style jsx global>
          {`
            .page-transition-opacity-enter {
              opacity: 0.01;
            }
            .page-transition-opacity-enter-active {
              opacity: 1;
              transition: opacity 300ms;
            }
            .page-transition-opacity-exit {
              opacity: 1;
            }
            .page-transition-opacity-exit-active {
              opacity: 0.01;
              transition: opacity 300ms;
            }

            .page-transition-enter {
              opacity: 0;
              transform: translate3d(0, 20px, 0);
            }
            .page-transition-enter-active {
              opacity: 1;
              transform: translate3d(0, 0, 0);
              transition: opacity ${TIMEOUT}ms, transform ${TIMEOUT}ms;
            }
            .page-transition-exit {
              opacity: 1;
            }
            .page-transition-exit-active {
              opacity: 0.01;
              transition: opacity ${TIMEOUT}ms;
            }

            .loading-indicator-appear,
            .loading-indicator-enter {
              opacity: 0;
            }
            .loading-indicator-appear-active,
            .loading-indicator-enter-active {
              opacity: 1;
              transition: opacity ${TIMEOUT}ms;
            }
          `}
        </style>
        <GlobalStyles />
      </>
    );
  }
}

export default MyApp;
