export const ADD_ADMIN_TO_CHAT = 'v2/projects/twilio/add_admin_to_chat/';
export const API_LOGOUT = 'users/set_user_offline/';
export const CATEGORIES = 'categories/';
export const CLIENT_DECLINED = 'v2/projects/client_declined/';
export const CREATE_EXTRA_PROJECT_CHANNEL = 'users/twilio_create_extra_project_channel_for_partner_admin/';
export const GET_BATCH_DETAIL = 'batchrequests/';
export const GET_BATCH_REQUESTS = 'v2/batches/';
export const GET_CLIENT_CHAT = 'users/get_client_chat/';
export const GET_FIREBASE_TOKENS = 'v2/firebase/get_tokens';
export const GET_PARTNER_CHAT = 'users/get_partner_chat/';
export const GET_PARTNER_PROPOSAL = 'v2/network_partner/';
export const GET_PARTNER_STATUS = 'v2/projects/get_partner_status/';
export const GET_TAG_LIST = 'tags/search_list/';
export const GET_TWILIO_TOKEN = 'users/get_twilio_token/';
export const GET_USER_BY_EMAIL = 'users/?q='; // This does a substring search across multiple fields, including email
export const GET_USER_BY_EMAIL_V3 = 'v3/users?email='; // This searches for a specific email by exact match
export const INVITE_CHAT_USER = 'users/twilio_create_member_resource/';
export const JOIN_CHANNEL = 'v2/projects/twilio/join_channel/';
export const LOGIN = 'token/';
export const MATCH_PROJECT = 'projectsproposal/match_mutilple_project/';
export const PROJECTS = 'projects/';
export const PROJECT_REPORTS = 'projectreports/';
export const PROJECT_SCOPE = 'scope/';
export const REMATCH_PROJECT = 'projectsproposal/unmatch_mutilple_project/';
export const REMOVE_ADMIN_FROM_CHAT = 'v2/projects/twilio/remove_admin_to_chat/';
export const RESEND_ACTIVATION_EMAIL = 'v2/account/resend_activation_email/';
export const RESET_PASSWORD = 'account/reset_password/';
export const SCOPE_SERVICE = 'scope_service/';
export const SET_NEW_PASSWORD = 'account/set_password/';
export const SWITCH_PROJECT_STATUS = 'projects/status/';
export const TAGS = 'tags/';
export const TEAMS = 'teams/';
export const UNMATCH_ADMIN = 'v2/projects/admin_unmatch/';
export const UPLOAD_FILE = 'v2/projects/files/';
export const UPLOAD_USER_AVATAR = 'users/upload_avatar/';
export const USERS = 'users/';
export const USER_GET_LATEST_REPORTS = 'users/get_latest_reports';