import { ServiceType } from '@gtintel/platform-types';
import { api } from '..';
export const getDraftList = params => api.scopeBuilder.getDraftList(params).then(response => Promise.resolve(response.data));
export const getTagList = tags => api.scopeBuilder.getTagList(tags).then(response => Promise.resolve(response.data));
export const getProjectScope = id => api.scopeBuilder.get(id).then(response => response.data);
export const createProjectScope = data => api.scopeBuilder.create(data).then(response => response.data);
export const updateProjectScope = data => api.scopeBuilder.update(data).then(response => response.data);
export const deleteProjectScope = id => api.scopeBuilder.delete(id).then(response => response.data);

// -----------------------------------------------------------------------------
// DueDiligence Scope
// -----------------------------------------------------------------------------
export const createProjectScopeDueDiligence = data => api.scopeService.createDueDiligence(data).then(response => response.data);
export const updateProjectScopeDueDiligence = data => api.scopeService.updateDueDiligence(data).then(response => response.data);
export const removeProjectScopeDueDiligence = id => api.scopeService.deleteDueDiligence(id).then(response => response.data);
export const getAllScopeDueDiligence = scopeId => api.scopeService.getAllScopeDueDiligence(scopeId).then(response => response.data);

// -----------------------------------------------------------------------------
// SiteVisit Scope
// -----------------------------------------------------------------------------
export const createProjectScopeSiteVisit = data => api.scopeService.createSiteVisit(data).then(response => response.data);
export const updateProjectScopeSiteVisit = data => api.scopeService.updateSiteVisit(data).then(response => response.data);
export const removeProjectScopeSiteVisit = id => api.scopeService.deleteSiteVisit(id).then(response => response.data);
export const getAllScopeSiteVisit = scopeId => api.scopeService.getAllScopeSiteVisit(scopeId).then(response => response.data);

// -----------------------------------------------------------------------------
// HumanSourceEnquiry Scope
// -----------------------------------------------------------------------------
export const createProjectScopeHumanSourceEnquiry = data => api.scopeService.createHumanSourceEnquiry(data).then(response => response.data);
export const updateProjectScopeHumanSourceEnquiry = data => api.scopeService.updateHumanSourceEnquiry(data).then(response => response.data);
export const removeProjectScopeHumanSourceEnquiry = id => api.scopeService.deleteHumanSourceEnquiry(id).then(response => response.data);
export const getAllScopeHumanSourceEnquiry = scopeId => api.scopeService.getAllScopeHumanSourceEnquiry(scopeId).then(response => response.data);

// -----------------------------------------------------------------------------
// SomethingElse Scope
// ----------------------------------------------------------------------------
export const createProjectScopeSomethingElse = data => api.scopeService.createSomethingElse(data).then(response => response.data);
export const updateProjectScopeSomethingElse = data => api.scopeService.updateSomethingElse(data).then(response => response.data);
export const removeProjectScopeSomethingElse = id => api.scopeService.deleteSomethingElse(id).then(response => response.data);
export const getAllScopeSomethingElse = scopeId => api.scopeService.getAllScopeSomethingElse(scopeId).then(response => response.data);

// -----------------------------------------------------------------------------
// Document Retrieval
// -----------------------------------------------------------------------------
export const createProjectScopeDocumentRetrieval = data => api.scopeService.createDocumentRetrieval(data).then(response => response.data);
export const updateProjectScopeDocumentRetrieval = data => api.scopeService.updateDocumentRetrieval(data).then(response => response.data);
export const removeProjectScopeDocumentRetrieval = id => api.scopeService.deleteDocumentRetrieval(id).then(response => response.data);
export const getAllScopeDocumentRetrieval = scopeId => api.scopeService.getAllScopeDocumentRetrieval(scopeId).then(response => response.data);
export const submitScope = scopeId => api.scopeBuilder.createProject(scopeId).then(response => response.data);
export const getFindAllScopeAction = type => {
  switch (type) {
    case ServiceType['Due Diligence']:
      return getAllScopeDueDiligence;
    case ServiceType['Site Visit']:
      return getAllScopeSiteVisit;
    case ServiceType['Something Else']:
      return getAllScopeSomethingElse;
    case ServiceType['Human Source Enquiry']:
      return getAllScopeHumanSourceEnquiry;
    case ServiceType['Document Retrieval']:
      return getAllScopeDocumentRetrieval;
    default:
      return () => undefined;
  }
};
export const getCreateScopeAction = type => {
  switch (type) {
    case ServiceType['Due Diligence']:
      return createProjectScopeDueDiligence;
    case ServiceType['Site Visit']:
      return createProjectScopeSiteVisit;
    case ServiceType['Something Else']:
      return createProjectScopeSomethingElse;
    case ServiceType['Human Source Enquiry']:
      return createProjectScopeHumanSourceEnquiry;
    case ServiceType['Document Retrieval']:
      return createProjectScopeDocumentRetrieval;
    default:
      return () => undefined;
  }
};
export const getUpdateScopeAction = type => {
  switch (type) {
    case ServiceType['Due Diligence']:
      return updateProjectScopeDueDiligence;
    case ServiceType['Site Visit']:
      return updateProjectScopeSiteVisit;
    case ServiceType['Something Else']:
      return updateProjectScopeSomethingElse;
    case ServiceType['Human Source Enquiry']:
      return updateProjectScopeHumanSourceEnquiry;
    case ServiceType['Document Retrieval']:
      return updateProjectScopeDocumentRetrieval;
    default:
      return () => undefined;
  }
};
export const getRemoveScopeAction = type => {
  switch (type) {
    case ServiceType['Due Diligence']:
      return removeProjectScopeDueDiligence;
    case ServiceType['Site Visit']:
      return removeProjectScopeSiteVisit;
    case ServiceType['Something Else']:
      return removeProjectScopeSomethingElse;
    case ServiceType['Human Source Enquiry']:
      return removeProjectScopeHumanSourceEnquiry;
    case ServiceType['Document Retrieval']:
      return removeProjectScopeDocumentRetrieval;
    default:
      return () => undefined;
  }
};
export const getProjectScopeWithServices = async id => {
  const projectScope = await getProjectScope(id);
  const services = await getFindAllScopeAction(projectScope.service_type)(projectScope.id);
  return {
    ...projectScope,
    service_scope: services
  };
};
export const getJurisdictions = () => api.jurisdictions.list().then(response => response.data);